<template>
    <div class="mt-3 px-xl-5">
        <b-row class="justify-content-end">
            <b-col xl="3" md="6">
                <b-input-group class="mt-3">
                    <b-form-input placeholder="Buscar" class="rounded-0" size="sm"></b-form-input>
                    <b-input-group-append>
                        <b-button class="main-btn" size="sm">
                            <b-icon-search></b-icon-search>
                        </b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>
        </b-row>
        <b-row class="mt-3">           
            <b-col class="text-end">
                <b-button class="main-btn">
                    <b-icon-envelope scale="1.2"></b-icon-envelope>
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <h6 class="page-title pb-2 mt-3">LISTADO DE NOTIFICACIONES</h6>
                <b-card no-header no-body class="rounded-0 shadow-sm border-0">
                    <b-table id="notifications-table"
                             :current-page="currentPage"
                             :per-page="perPage"
                             small
                             stacked="lg"
                             :items="items"
                             :fields="fields"
                             show-empty
                             empty-text="No hay notificaciones que mostrar">
                        <template v-slot:cell(requestType)>
                            <b-icon-envelope scale="1.2"></b-icon-envelope>
                        </template>
                        <template v-slot:cell(comment)>
                            <b-icon-chat-left scale="1.2"></b-icon-chat-left>
                        </template>
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
        <b-row class="mb-5 mt-4">
            <b-col>
                <b-pagination v-model="currentPage"
                              :total-rows="rows"
                              :per-page="perPage"
                              size="sm"
                              class="my-0 justify-content-end">
                    <template #first-text>
                        <span><b-icon-chevron-double-left scale="1.3"></b-icon-chevron-double-left></span>
                    </template>
                    <template #prev-text>
                        <span><b-icon-chevron-left scale="1.3"></b-icon-chevron-left></span>
                    </template>
                    <template #next-text>
                        <span><b-icon-chevron-right scale="1.3"></b-icon-chevron-right></span>
                    </template>
                    <template #last-text>
                        <span><b-icon-chevron-double-right scale="1.3"></b-icon-chevron-double-right></span>
                    </template>
                </b-pagination>
            </b-col>
        </b-row>
    </div>
</template>

<script>
    export default {
        name: "notifications",
        data() {
            return {
                fields: [
                    { key: 'date', label: "Fecha" },
                    { key: 'applicant', label: "Solicitante" },
                    { key: 'topic', label: "Asunto" },
                    { key: 'legalEntity', label: "Entidades jur\u00eddicas" },
                    { key: 'registryNumber', label: "Num. registro" },
                    { key: 'requestType', label: "Tipo de petici\u00f3n" },                  
                    { key: 'comment', label: "Comentarios" }
                ],
                items: [
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment:'' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                    { date: 'Fecha notificacion', applicant: 'SuperIntendencia', topic: 'Asunto notificacion', legalEntity: 'Entidades juridicas', registryNumber: 'Numero de registro', requestType: '', comment: '' },
                ],
                rows: 1,
                currentPage: 1,
                perPage: 10
            };
        },
        mounted() {            
            this.rows = this.items.length
        }     
    };
</script>
<style scoped>
    @media (min-width: 1024px) {
        ::v-deep #notifications-table > tbody > tr > td[aria-colindex="6"], ::v-deep #notifications-table > tbody > tr > td[aria-colindex="7"] {
            text-align: center;
        }
    }  
</style>